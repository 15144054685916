import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BreadcrumbJsonLd } from 'next-seo';
import cn from 'src/classnames';
import usePageContext from 'utils/hooks/usePageContext';
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import SpriteIcon from 'components/uiLibrary//SpriteIcon';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import { withTranslation } from 'src/i18n';
import { selectLanguage } from 'store/selectors';

import NextLink from '../NextLink';
import styles from './Breadcrumbs.module.scss';

const trackingData = {
  section: SECTIONS.BREADCRUMBS,
};

const baseURL = process.env.FRONTBASE_URL;

const Breadcrumbs = ({ customBreadcrumbs, t, className, maxItems, language }) => {
  const { navigate } = usePageContext();
  const links = customBreadcrumbs || [];

  const isNotLastItem = index => index !== links.length - 1;
  const itemListElements = [];

  const homepageLinkProps = useMemo(() => navigate.getLinkProps({ onlyLinkProps: true }), [navigate]);

  return (
    <div className={cn(styles.root, className)}>
      <MUIBreadcrumbs separator="›" aria-label="breadcrumb" maxItems={maxItems} className={styles.separator}>
        {links.map((link, index) => {
          const translatedTitle = t(link.title);
          const title = translatedTitle || link.title;
          if (link?.path && link?.path !== '/')
            itemListElements.push({
              position: index,
              name: title?.replace(/"/g, "'"),
              item: encodeURI(`${baseURL.replace(/\/$/, '')}${link.path}${language}`),
            });

          if (index === 0) {
            return (
              <div key={link?.title}>
                <NextLink
                  prefetch={false}
                  {...homepageLinkProps}
                  key={link.title}
                  className={styles.listItem}
                  trackingData={{
                    ...trackingData,
                    component: COMPONENTS.HOME,
                    meta: { index, path: link.path },
                  }}
                >
                  <SpriteIcon icon="home" />
                </NextLink>
              </div>
            );
          }

          if (isNotLastItem(index) || links.length === 1) {
            return (
              <div key={link?.title}>
                <NextLink
                  prefetch={false}
                  key={link.title}
                  {...(link?.href ? { as: link.as || link.path } : {})}
                  href={link?.href || link?.path}
                  className={styles.listItem}
                  trackingData={{
                    ...trackingData,
                    meta: { index, path: link.path },
                  }}
                >
                  {t(link.title)}
                </NextLink>
              </div>
            );
          }

          return (
            <Typography color="textPrimary" className={cn(styles.listItem, styles.active)} key={link.title}>
              {t(link.title)}
            </Typography>
          );
        })}
      </MUIBreadcrumbs>
      <br />
      {itemListElements.length > 0 && <BreadcrumbJsonLd itemListElements={itemListElements} />}
    </div>
  );
};

Breadcrumbs.getInitialProps = async () => ({
  namespacesRequired: ['translation'],
});

Breadcrumbs.propTypes = {
  t: PropTypes.func.isRequired,
  customBreadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  className: PropTypes.string,
  maxItems: PropTypes.number,
};
const mapStateToProps = createStructuredSelector({
  language: selectLanguage(),
});

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect, withTranslation('translation'))(Breadcrumbs);
